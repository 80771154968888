button, .btn {
    border: 0;
    background: #00b894;
    color: inherit;
    text-transform: uppercase;
    font-size: 1.2rem;
    padding: 1px 35px;
    border-radius: 2px;
    line-height: 22px;
    text-decoration: none;
}