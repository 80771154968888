header {
    background: #6c5ce7;
    padding: 12px;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
}

header h1 {
    font-size: 2rem;
    font-weight: 200;
}

.search {
    display: flex;
}

.search input {
    margin-right: 5px;
    width: 16rem;
}